<template>
  <ul class="mobile-app-page__preview-list">
    <li>
      <div class="mobile-app-page__preview-list-image-wrapper">
        <img
          src="/assets/img/mobile-app/screens/1.png"
          srcset="
            /assets/img/mobile-app/screens/1@2x.png 2x,
            /assets/img/mobile-app/screens/1@3x.png 3x
          "
          alt="Демонстрация экрана «Функциональный профиль»"
        >
      </div>
      <span>Функциональный<br>профиль</span>
    </li>
    <li>
      <div class="mobile-app-page__preview-list-image-wrapper">
        <img
          src="/assets/img/mobile-app/screens/2.png"
          srcset="
            /assets/img/mobile-app/screens/2@2x.png 2x,
            /assets/img/mobile-app/screens/2@3x.png 3x
          "
          alt="Демонстрация экрана «Расчетный лист»"
        >
      </div>
      <span>Расчетный<br>лист</span>
    </li>
    <li>
      <div class="mobile-app-page__preview-list-image-wrapper">
        <img
          src="/assets/img/mobile-app/screens/3.png"
          srcset="
            /assets/img/mobile-app/screens/3@2x.png 2x,
            /assets/img/mobile-app/screens/3@3x.png 3x
          "
          alt="Демонстрация экрана «Планирование отпуска»"
        >
      </div>
      <span>Планирование<br>отпуска</span>
    </li>
    <li>
      <div class="mobile-app-page__preview-list-image-wrapper">
        <img
          src="/assets/img/mobile-app/screens/4.png"
          srcset="
            /assets/img/mobile-app/screens/4@2x.png 2x,
            /assets/img/mobile-app/screens/4@3x.png 3x
          "
          alt="Демонстрация экрана «График отпусков»"
        >
      </div>
      <span>График<br>отпусков</span>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'MobileAppPagePreview',
  }
</script>

<style lang="scss" scoped>

@mixin mediaQuery($media: min, $viewport: 768px) {
  @if $media == min {
    @media (min-width: $viewport) {
      @content;
    }
  }

  @else if $media == max {
    @media (max-width: $viewport - 1) {
      @content;
    }
  }
}

  .mobile-app-page__preview-list {
    position: relative;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    display: flex;
    align-items: center;
    min-height: 670px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translate(0, -100%) rotate(-2deg);
      background: radial-gradient(rgba(0, 0, 0, .3) 0%, transparent 70%);
      border-radius: 14px;
      width: 70%;
      height: 56px;
    }

    li {
      width: 301.6px;
      margin-left: 0;
      transition: all .2s linear;
      z-index: 4;

      @include mediaQuery(min, 1920px) {
        width: 377px;
      }

      &:nth-child(2) {
        width: 289.6px;
        z-index: 3;

        @include mediaQuery(min, 1920px) {
          width: 362px;
        }
      }

      &:nth-child(3) {
        width: 277.6px;
        z-index: 2;

        @include mediaQuery(min, 1920px) {
          width: 347px;
        }
      }

      &:nth-child(4) {
        width: 265.6px;
        z-index: 1;

        @include mediaQuery(min, 1920px) {
          width: 332px;
        }
      }

      &:not(:first-child) {
        margin-left: -10%;
      }

      img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
        margin-bottom: 20px;
      }

      span {
        opacity: .8;
      }
    }

    &-image-wrapper {
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translate(-50%, 0);
        background: radial-gradient(rgba(0, 0, 0, .6) 0%, transparent 70%);
        border-radius: 14px;
        width: 70%;
        height: 14px;
      }
    }
  }
</style>
