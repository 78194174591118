<template>
  <Info/>
</template>

<script>
import Info from './components/mobile_app/Info.vue'

export default {
  name: 'App',
  components: {
    Info
  }
}
</script>

<style lang="scss">
    @import "assets/fonts/_fonts.scss";
    @import "assets/normalize.css";
</style>
