<template>
  <div class="mobile-app-page">
    <div class="mobile-app-page__container mobile-app-page__content-row">
      <div class=" mobile-app-page__content-column">
        <div class="mobile-app-page__app-preview">
          <info-app-preview />
        </div>
      </div>
      <div
        class="mobile-app-page__content-mobile mobile-app-page__content-column"
      >
        <img
          src="/assets/img/mobile-app/logo.svg"
          alt="SmartStaff logo"
          class="mobile-app-page__logo"
        >
        <h1 class="mobile-app-page__title">SmartStaff</h1>
        <p class="mobile-app-page__description">
          Ищите контакты коллег, читайте новости своей&nbsp;организации,
          смотрите зарплатный лист и&nbsp;согласовывайте график отпусков
          прямо в&nbsp;своём мобильном телефоне с&nbsp;помощью
          приложения&nbsp;SmartStaff
        </p>
        <ul class="mobile-app-page__btn-list">
          <li>
            <a
              href="https://apps.apple.com/ru/app/smartstaff/id1507351613"
              title="App Store"
              class="mobile-app-page__btn"
            >
              <img src="/assets/img/mobile-app/apple@1x.svg">
            </a>
          </li>
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=ru.dit.smartstaff&hl=ru"
              title="Google play"
              class="mobile-app-page__btn"
            >
              <img src="/assets/img/mobile-app/google@1x.svg">
            </a>
          </li>
        </ul>
        <p class="mobile-app-page__call-to-action">Скачайте сейчас</p>
      </div>
    </div>
    <div class="mobile-app-page__dec-waves-wrapper">
      <img
        src="/assets/img/mobile-app/waves-mobile.svg"
        class="mobile-app-page__dec-waves mobile-app-page__dec-waves--mobile"
      >
      <img src="/assets/img/mobile-app/waves.svg" class="mobile-app-page__dec-waves">
    </div>
  </div>
</template>

<script>
  import infoAppPreview from './InfoAppPreview.vue'

  export default {
    name: 'MobileAppPage',
    components: { infoAppPreview, }
  }
</script>

<style lang="scss" scoped>
@mixin mediaQuery($media: min, $viewport: 768px) {
  @if $media == min {
    @media (min-width: $viewport) {
      @content;
    }
  }

  @else if $media == max {
    @media (max-width: $viewport - 1) {
      @content;
    }
  }
}

  .mobile-app-page {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    max-width: 100vw;
    max-height: 100vh;
    height: 100vh;
    font-family: 'SF UI Text', serif;
    font-size: 14px;
    line-height: 1.43;
    color: #fff;
    text-align: center;
    background-color: #1e2131;
    min-height: 485px;
    max-height: stretch;

    @include mediaQuery(min, 1300px) {
      font-size: 16px;
      min-height: 670px;
    }

    @include mediaQuery(min, 1920px) {
      font-size: 20px;
      line-height: 1.5;
      min-height: 825px;
    }

    &__dec-waves {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      display: inline-block;
      opacity: 0;
      min-width: 250vw;
      height: auto;
      object-fit: contain;
      transform: translate(-45%, 0);

      @include mediaQuery(min, 1300px) {
        min-width: 200vw;
        opacity: 1;
      }

      @include mediaQuery(min, 1920px) {
        opacity: 1;
      }

      &-wrapper {
        position: relative;
        flex-grow: 1;

        &::before {
          content: '';
          position: absolute;
          top: 40%;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: #3e53b4;
        }
      }

      &--mobile {
        opacity: 1;

        @include mediaQuery(min, 1300px) {
          opacity: 0;
        }
      }
    }

    &__app-preview {
      display: none;

      @include mediaQuery(min, 1300px) {
        display: block;
        margin-right: -340px;
      }
    }

    &__content-row {
      position: relative;
      z-index: 1;
      padding: 60px 0 10px;

      @include mediaQuery(min, 1300px) {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 60px 0 20px;
        margin: 0 -50px;
      }

      @include mediaQuery(min, 1920x) {
        margin: 0 -100px;
      }
    }

    &__content-column {
      box-sizing: border-box;
      padding: 0 16px;

      @include mediaQuery(min, 1300px) {
        padding: 0 50px;
      }

      @include mediaQuery(min, 1920px) {
        padding: 0 100px;
      }

      &:first-child {
        padding-right: 0;
      }
    }

    &__content-mobile {
      max-width: 450px;

      @include mediaQuery(min, 1300px) {
        &::before {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(0, -50%);
          display: inline-block;
          width: 50vw;
          height: 50vw;
          background: radial-gradient(rgba(80, 100, 192, .15) 0%, transparent 50%);
          bottom: 50%;
          z-index: -1;
        }
      }

      @include mediaQuery(min, 1500px) {
        max-width: 100%;
      }
    }

    &__logo {
      display: inline-block;
      width: 66px;
      height: 66px;
      margin: 0 auto 12px auto;

      @include mediaQuery(min, 1300px) {
        display: none;
      }
    }

    &__title {
      font-family: 'SF UI Text', serif;
      font-weight: 900;
      font-size: 36px;
      letter-spacing: -.49px;
      line-height: 1;
      text-shadow: 0 4px 32px rgba(0, 0, 0, .5);
      margin: 0 0 20px;

      @include mediaQuery(min, 1300px) {
        font-size: 58px;
        letter-spacing: -.97px;
        margin-bottom: 32px;
      }

      @include mediaQuery(min, 1920px) {
        font-size: 72px;
      }
    }

    &__description {
      max-width: 320px;
      margin: 0 auto 56px auto;

      @include mediaQuery(min, 1300px) {
        max-width: 426px;
      }
    }

    &__btn-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      list-style-type: none;
      padding: 0;

      li {
        width: 100%;

        &:first-child {
          margin-bottom: 14px;

          @include mediaQuery(min, 1300px) {
            margin: 0 0 8px;
          }
        }

        @include mediaQuery(min, 1300px) {
          width: auto;
          padding: 0 12px;
        }
      }

      @include mediaQuery(min, 1300px) {
        flex-direction: row;
        align-items: flex-start;
        margin: 0 -12px;
      }
    }

    &__btn {
      display: block;
      position: relative;
      width: 100%;
      height: 62px;
      margin: 0 auto;

      @include mediaQuery(min, 1300px) {
        width: 180px;
        margin: 0;
      }

      @include mediaQuery(min, 1920px) {
        width: 206px;
        height: 72px;
        margin: 0;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        max-width: 280px;
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%);
        pointer-events: none;

        @include mediaQuery(min, 1300px) {
          width: 140%;
          max-width: 140%;
        }
      }
    }

    &__call-to-action {
      margin: 0;
      opacity: 0;
      transition: opacity .2 linear;

      @include mediaQuery(min, 1300px) {
        opacity: 1;
      }
    }

    &__container {
      box-sizing: border-box;
      min-width: 320px;
      max-width: 100%;
      margin: 0 auto -200px;
      transition: margin-bottom .2s linear;

      @include mediaQuery(min, 1300px) {
        margin-bottom: -545px;
      }

      @include mediaQuery(min, 1920px) {
        margin-bottom: -500px;
      }
    }
  }
</style>
